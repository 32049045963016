import {Component, Input, OnInit, ElementRef} from '@angular/core';
import {ScreenSizeService} from "@gatho/features/lobby/services/screen-size.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {BrandingService} from '@gatho/services/branding/BrandingService';

@Component({
  selector: 'marketing-nav',
  templateUrl: './marketing-nav.component.html',
  styleUrls: ['./marketing-nav.component.scss']
})

export class MarketingNavComponent implements OnInit {

    isMobile!: boolean;
    screenSizeServiceSub: Subscription;
    public logoPath: string = '../../../../assets/images/logos/gatho-logo-gradient.svg';

    constructor(
        private _screenSizeService: ScreenSizeService,
        private _branding: BrandingService,
        private hostElementRef: ElementRef,
        public router: Router
      ) {
        this.screenSizeServiceSub = _screenSizeService.screenSizeUpdated$.subscribe(
          (isMobile) => (this.isMobile = isMobile)
        );
        this.setBranding();

      }

      setBranding() {
        this._branding.setCssProperty(this.hostElementRef, '--nav-hover-gradient-start', 'primaryButtonStartColorHover');
        this._branding.setCssProperty(this.hostElementRef, '--nav-hover-gradient-start', 'primaryButtonEndColorHover');
      }

    ngOnInit(): void {
    }
}
